<template>
  <div>
    <b-container>
      <div class="sanofi border-top border-bottom py-3 my-5">
        <a
          href="https://www.sanofi.ch/"
          class="d-block partner-link sanofi"
          target="_blank"
          rel="noopener"
        >
          <img v-lazy="logoPath" alt="Sanofi" class="img-fluid" />
          <span class="sr-only">{{ translate('PageFooter.LogoDescription')}}}</span>
        </a>
      </div>
    </b-container>

    <b-container>
      <footer class="footer">
        <b-row>
          <b-col order-lg="2" lg class="py-3">
            <div
              class="d-lg-flex flex-column align-items-center align-items-xl-start"
            >
              <div>
                <div class="h2">{{ translate('PageFooter.Produkte') }}</div>
                <b-nav vertical>
                  <b-nav-item
                    v-for="item in products"
                    :key="item.id"
                    :active="item.isActive"
                    :href="item.url"
                  >
                    <font-awesome-icon icon="angle-right" size="lg" />
                    <span v-html="item.title" />
                  </b-nav-item>
                </b-nav>
              </div>
            </div>
          </b-col>

          <b-col order-lg="3" xl="3" class="py-3">
            <div class="h3 hyphenate">{{ translate('PageFooter.Info.Header') }}</div>
            <p v-html="translate('PageFooter.Info.Details')">
            </p>
          </b-col>

          <b-col order-lg="1" lg="4" class="py-3">
            <div class="h2">{{ translate('PageFooter.Company.Header') }}</div>
            <p class="pt-2 mb-4" v-html="translate('PageFooter.Company.Details')">
            </p>
            <footer-navigation-service />
          </b-col>
        </b-row>
      </footer>
    </b-container>

    <div class="copyright py-4 my-3">
      <b-container>
        <div class="text-center">
          <div v-html="translate('PageFooter.Copyright.Text')">
          </div>
        </div>
      </b-container>
    </div>

    <b-container><pflicht-text /></b-container>

    <!-- 2018-19 Ilum:e AG, MJP -->

    <div class="scoll-2-top py-1 px-4">
      <div class="text-right">
        <font-awesome-icon
          :icon="['fas', 'angle-double-up']"
          size="2x"
          aria-hidden="true"
          class="scroll-2-top"
          @click="scrollTop"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as helper from "../lib/helper";

import FooterNavService from "./FooterNavService.vue";
import PflichtText from "./PflichtText.vue";
import {transBio} from "../lib/helper";

let products = [];
// let tipps = [];
let mainmenu = JSON.parse(JSON.stringify(window.mainmenu || [])); // duplicate mainmenu, don't modify original

let fillProducts = function(items) {
  items.forEach(function(item) {
    if (!item.viewBag.isHidden || item.viewBag.isHidden == 0) {
      if (item.items && item.items.length) {
        fillProducts(item.items);
      } else {
        products.push(item);
      }
    }
  });
};

mainmenu.forEach(function(item) {
  if (!item.viewBag.isHidden || item.viewBag.isHidden == 0) {
    if (window.appdata.lang == "fr") {
        if (item.title == "Produits" && item.items && item.items.length) {
            helper.makeNavSanofi(item.items);
            fillProducts(item.items);
        }
    } else {
        if (item.title == "Produkte" && item.items && item.items.length) {
            helper.makeNavSanofi(item.items);
            fillProducts(item.items);
        }
    }

  }
});

export default {
  name: "PageFooter",
  components: {
    "footer-navigation-service": FooterNavService,
    "pflicht-text": PflichtText
  },
  data: function() {
    return {
      products: products,
      // tipps: tipps,
      thisYear: new Date().getFullYear()
    };
  },
  computed: {
    logoPath: function() {
      if (
        window.assetrevisions.hasOwnProperty("img/layout/footer/sanofi.svg")
      ) {
        return (
          "/themes/mucosolvan/dist/" +
          window.assetrevisions["img/layout/footer/sanofi.svg"]
        );
      } else {
        return "/themes/mucosolvan/dist/assets/img/layout/footer/sanofi.svg";
      }
    }
  },
  methods: {
    translate: function(text) {
      return helper.transBio(text);
    },
    scrollTop: function() {
      this.$scrollTo(document.getElementById("oc-app"), 333);
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/custom";
@import "../../styles/mixins";

.footer {
  a {
    font-weight: $font-weight-bold;
  }
}

.copyright {
  background-color: $border-color;
}

.sanofi img {
  width: 33.3%;
  max-width: 200px;
}

.nav-item {
  .nav-link {
    padding-left: 0;
    padding-bottom: 0;
  }

  .active {
    color: $muted;
  }

  .svg-inline--fa {
    color: $muted;
    position: relative;
    top: 0;
  }
}

.scroll-2-top {
  cursor: pointer;
}
</style>
