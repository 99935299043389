<template>
  <div>
    <div class="mt-2 mb-3"><strong>{{ translate('ResultDual.ResultText') }}</strong></div>
    <hr />

    <b-row class="results justify-content-center">
      <div class="col-sm-6 mb-2"><resultCard :result="dual" /></div>

      <div class="col-sm-6 mb-2"><resultCard :result="dualtablette" /></div>
    </b-row>

    <p>
      <a href="/produkte" class="nav-link">
        <font-awesome-icon class="text-muted" icon="angle-right" />
        <strong class="alle-produkte">{{translate('HustenText.General.AllProdukte')}}</strong>
      </a>
    </p>
  </div>
</template>

<script>
import * as helper from "../../lib/helper";
import resultCard from "../ResultCard.vue";
import {transBio} from "../../lib/helper";

let mainmenu = JSON.parse(JSON.stringify(window.mainmenu || [])); // duplicate mainmenu, don't modify original
let mucosolvan = {};
let rest = {};
mainmenu.forEach(function(item) {
  let crumb = [];
  let from = "from-left";
  let rc = 0;

  let langCheck    = "Produkte";

  if (window.appdata.lang == "fr") {
      langCheck    = "Produits";
  }

    if (!item.viewBag.isHidden || item.viewBag.isHidden == 0) {
    if (item.title == langCheck) {
      item.items.forEach(function(item) {
        if (!item.viewBag.isHidden || item.viewBag.isHidden == 0) {
            helper.makeNavSanofi(item.items);
            let mc = 0;
            item.items.forEach(function(product) {
              from = from == "from-left" ? "from-right" : "from-left";
              crumb = product.url.split("/");
              crumb.shift();
              product.crumb = crumb;
              product.slug = crumb.join("-");
              product.from = from;
              product.subtitle = product.viewBag.subTitle
                ? helper.makeTextSanofi(product.viewBag.subTitle)
                : "";
              product.title = product.title.replace("&reg;", "&reg;<br>");
              mucosolvan[product.slug] = product;
              mc++;
            });
        }
      });
    }
  }
});


export default {
  name: "ResultDual",
  components: {
    resultCard
  },
  data: function() {
    if (window.appdata.lang == "fr") {
        return {
            dual: mucosolvan["fr-produit-sirup-dual"],
            dualtablette: mucosolvan["fr-produit-sirup-tabletten"]
        };
    }

    return {
      dual: mucosolvan["produkte-sirup-dual"],
      dualtablette: mucosolvan["produkte-sirup-tabletten"]
    };
  },
    methods: {
        translate: function(text) {
            return transBio(text);
        },
    }
};
</script>

<style lang="scss">
@import "../../../styles/custom";
@import "../../../styles/mixins";
.results {
  .card {
    .h3 {
      @include responsive-font-size($font-size-base);
    }
  }
}
</style>
