<template>
  <div id="related-content" class="container">
    <div v-if="results.length > 0" class="my-5">
      <p class="h2 text-center">{{translate('RelatedCards.Also')}}</p>

      <div v-if="!results.length" class="search-spinner text-center">
        <font-awesome-icon :icon="['fas', 'spinner-third']" size="4x" spin />
      </div>
      <b-row class="results-wrap justify-content-center mt-3">
        <b-col
          v-for="result in results"
          :key="result.slug"
          v-in-viewport.once="{ top: 100, bottom: 100 }"
          :md="result.span"
        >
          <resultCard :result="result" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import * as helper from "../lib/helper";

import resultCard from "./ResultCard.vue";

let from = "from-left";

export default {
  name: "RelatedCards",
  components: {
    resultCard
  },
  data: function() {
    return {
      results: []
    };
  },
  mounted: function() {
    this.getRelated();
  },
  methods: {
    translate: function(text) {
        return helper.transBio(text);
    },
    getRelated: function() {
      let searchlist = window.related.split(",");
      if (searchlist.length === 3) {
        let vm = this;
        vm.results = [];
        this.axios
          .post("/api/btdsgn/searchDefault", { searchlist: searchlist })
          .then(function(response) {
            helper.makeNavSanofi(response.data);
            response.data.forEach(function(item) {
              from = from == "from-left" ? "from-right" : "from-left";
              item.from = from;
              item.span = 4;
              if (item.crumb[0] == "produkte" || item.crumb[0] == "produits") {
                item.title = item.title.replace("&reg;", "&reg;<br>");
              }
              if (item.crumb[0] == "wirkprinzip" || item.crumb[0] == "principeplus") {
                item.title = item.meta_title;
              }
            });
            vm.results = response.data;
          })
          .catch(function(error) {
            // handle error
            console.error(error);
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/custom";
@import "../../styles/mixins";

.results-wrap {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
