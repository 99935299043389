<template>
  <transition name="fade">
    <div v-show="active" id="main-menu" class="menu-overlay">
      <div
        id="mainmenu-scrollarea"
        v-bar="{
          preventParentScroll: true
        }"
        class="menu-content"
      >
        <div>
          <b-container>
            <div class="menu-scrollable-content mb-5 pb-5">
              <ul class="level-0 level-produkte nav flex-column">
                <li class="nav-item py-3 pl-3">
                  <a :href="getProductLink()" class="nav-link">
                    <font-awesome-icon icon="angle-right" />
                    {{translate('MainMenu.Produkte')}}
                  </a>
                </li>
              </ul>

              <div class="menu-family-teaser container"><menu-family /></div>

              <div class="mt-4 mb-5">
                <ul
                  id="main-navigation"
                  :class="`level-${level}`"
                  class="nav flex-column"
                >
                  <li
                    v-for="item in navigation"
                    :key="item.id"
                    v-b-toggle="
                      item.items && item.items.length
                        ? `submenu-${item.title}`
                        : false
                    "
                    class="nav-item py-3 pl-3"
                  >
                    <a
                      :id="`navlink-${item.title.toLowerCase()}`"
                      :href="item.items && item.items.length ? '#' : item.url"
                      :class="
                        item.isActive || item.isChildActive ? 'active' : ''
                      "
                      class="nav-link"
                    >
                      <font-awesome-icon
                        :rotation="item.rotation"
                        :size="item.angleSize"
                        icon="angle-right"
                      />
                      <span v-html="item.displayTitle" />
                    </a>
                    <b-collapse
                      v-if="item.items && item.items.length"
                      :id="`submenu-${item.title}`"
                      accordion="mainmenuaccordion"
                      @show="switchState(item);"
                      @hide="switchState(item);"
                      @shown="refreshScrollbar"
                      @hidden="refreshScrollbar"
                    >
                      <main-sub-menu :items="item.items" :level="level + 1" />
                    </b-collapse>
                  </li>
                </ul>
              </div>
            </div>
          </b-container>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import * as helper from "../lib/helper";
import {transBio} from "../lib/helper";

let mainNav = [];
let mainmenu = JSON.parse(JSON.stringify(window.mainmenu || [])); // duplicate mainmenu, don't modify original

mainmenu.forEach(function(item) {
  if (!item.viewBag.isHidden || item.viewBag.isHidden == 0) {
    item.displayTitle = item.title == "Produkte" ? "alle Produkte" : item.title;
    helper.makeNavSanofi(item.items);
    item.rotation = null;
    item.angleSize = item.title == "Produkte" ? "xs" : "sm";
    mainNav.push(item);
  }
});

export default {
  name: "MainMenu",
  components: {
    MainSubMenu: require("./MainSubMenu.vue").default,
    MenuFamily: require("./products/MenuFamily.vue").default
  },
  data: function() {
    return {
      active: false,
      scrollContainer: Object,
      navigation: mainNav,
      level: 0
    };
  },
  mounted: function() {
    var vm = this;
    /* this.navigation.forEach(function(item) {
      vm.$set(item, "rotation", null);
    }); */
    this.scrollContainer = document.getElementById("mainmenu-scrollarea");
    window.EventBus.$on("toggleMainMenu", function() {
      vm.menuToggle();
    });
  },
  methods: {
    translate: function(text) {
      return helper.transBio(text)
    },
    getProductLink : function() {
       let link = "/produkte";
       if (window.appdata.lang == "fr") {
           return "/fr/produit"
       }
       return link;
    },
    menuToggle: function() {
      let vm = this;
      vm.active = !vm.active;
      setTimeout(function() {
        window.dispatchEvent(new Event("resize"));
      }, 666);
    },
    switchState: function(item) {
      item.rotation = item.rotation === null ? 90 : null;
    },
    refreshScrollbar: function(item) {
      this.$vuebar.refreshScrollbar(this.scrollContainer);
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/custom";
@import "../../styles/mixins";

.menu-overlay {
  position: fixed;
  z-index: ($zindex-fixed + 1);
  top: 120px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($white, 0.98);

  .menu-content {
    height: calc(100vh - 93px);
    /* margin-right: -($grid-gutter-width / 2);
    margin-left: ($grid-gutter-width / 2); */
  }

  .menu-scrollable-content {
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2);
  }

  .vb > .vb-dragger {
    right: 0;
    opacity: 0;
  }
  @include media-breakpoint-up(md) {
    .vb > .vb-dragger {
      opacity: 1;
    }
  }

  .nav-item {
    > .nav-link {
      @include responsive-font-size($font-size-lg);
      font-weight: $headings-font-weight;
    }
  }

  .level-0 {
    > .nav-item {
      border-bottom: 1px solid $border-color;

      > .nav-link {
        @include responsive-font-size($h1-font-size);
        font-weight: $headings-font-weight;

        .svg-inline--fa {
          transition: transform 0.2s;
        }
      }
    }

    &.level-produkte {
      > .nav-item {
        border-bottom: 1px solid transparent;
      }
    }
  }

  .nav-link {
    &.active {
      color: $text-muted;
    }
  }
}
</style>
