<template>
  <b-container>
    <nav aria-label="breadcrumb" class="row">
      <ol class="breadcrumb">
        <li
          v-for="(item, index) in breadcrumbs"
          :key="`bc-${index}`"
          :class="
            item.active
              ? 'active' + (index == 3 ? ' d-none d-sm-inline' : '')
              : ''
          "
          :aria-current="item.active ? 'page' : null"
          class="breadcrumb-item"
        >
          <a v-if="!item.active" :href="item.url">
            <span v-html="item.text" />
          </a>
          <span v-else v-html="item.text" />
        </li>
      </ol>
    </nav>
  </b-container>
</template>

<script>
/*   <b-breadcrumb :items="breadcrumbs" /> */

import * as helper from "../lib/helper";

let breadcrumbs = [];
let items = JSON.parse(JSON.stringify(window.breadcrumbs || [])); // duplicate breadcrumbs, don't modify original
helper.makeNavSanofi(items);
items.forEach(function(item) {
  breadcrumbs.push({
    text: item.title,
    url: item.url,
    active: item.isActive
  });
});

export default {
  name: "BreadCrumbs",
  data: function() {
    return {
      breadcrumbs: breadcrumbs
    };
  }
};
</script>
