<template>
  <transition name="fade">
    <div id="navigation-main" :class="navbarClass" class="navbar-main clearfix">
      <div class="navbar-background">
        <b-container>
          <b-row>
            <b-col>

              <div style="width: 100%; text-align: right; margin-bottom: 0.2em !important;">
                <div id="sanofi_logo" style="padding-bottom: 0.4em;">
                  <a href="https://www.sanofi.ch/">
                    <img style="width: 80px;" alt="Sanofi" src="/themes/mucosolvan/dist/assets/img/layout/footer/sanofi.svg"></a>
                </div>
                <div id="lang_flags">
                  <a :href="getUrlFr">
                    <img style="height: 16px;" src="/themes/mucosolvan/dist/assets/img/other/fr.png">
                  </a>
                  <a :href="getUrlDe">
                    <img style="height: 16px;" src="/themes/mucosolvan/dist/assets/img/other/de.svg"></a>
                </div>
              </div>

              <div class="navbar-container d-flex">
                <div class="mr-1 order-1">
                  <a href="/" class="brand brand-top">
                    <img
                      v-lazy="logoPath"
                      alt="Bisolvon&reg;"
                      class="img-fluid navbar-logo"
                      width="187.5"
                      height="55"
                    />
                    <span class="sr-only">Bisolvon&reg;</span>
                  </a>
                </div>

                <div class="burger-wrap order-3 d-flex flex-row">
                  <div
                    id="menu-burger"
                    :class="burgerClass"
                    :aria-expanded="menuExpanded ? 'true' : 'false'"
                    class="burger-btn js-nav"
                    :aria-label="translate('Navbar.Menu.Label')"
                    aria-controls="main-menu"
                    @click="toggleMenu"
                  >
                    <div>
                      <span></span> <span></span> <span></span> <span></span>
                      <span></span>
                    </div>
                  </div>

                  <div class="burger-title" aria-hidden="true">{{translate('Navbar.Menu')}}</div>
                </div>

                <div class="order-2 navbar-search-col pr-3 ml-auto mr-0">
                  <navbar-search v-if="navsearch" />
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="position-relative">
        <svg data-name="menu-shadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4000 16" v-bind:svg-inline="''" v-bind:width="'100%'" v-bind:class="'menu-shadow position-absolute'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><linearGradient id="a" x1="2000" x2="2000" y2="16" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#1b2f70" stop-opacity=".15"/><stop offset="1" stop-opacity="0"/></linearGradient></defs><path d="M0 0v16h829.73C1158.75 8.45 1563 4 2000 4s841.25 4.45 1170.27 12H4000V0z" fill="url(#a)"/></svg>
      </div>
    </div>
  </transition>
</template>

<script>
import StickyBits from "stickybits";
import * as helper from "../lib/helper";
import NavbarSearch from "./NavbarSearch.vue";
import {transBio} from "../lib/helper";

const URLPart = appdata.altUrl || "";
const currentLang = appdata.lang || "de";

export default {
  name: "NavigationBar",
  components: {
    NavbarSearch
  },
  data: function() {
    return {
      navsearch: window.appdata.layout != "landing.htm",
      menuExpanded: false,
      burgerClass: "main",
      navbarClass: ""
    };
  },
  computed: {
    otherPath: function() {
        return "/themes/mucosolvan/dist/assets/img/layout/mucosolvan/";
    },
    logoPath: function() {
      if (
        window.assetrevisions.hasOwnProperty("img/layout/mucosolvan/logo.svg")
      ) {
        return (
          "/themes/mucosolvan/dist/" +
          window.assetrevisions["img/layout/mucosolvan/logo.svg"]
        );
      } else {
        return "/themes/mucosolvan/dist/assets/img/layout/mucosolvan/logo.svg";
      }
    },
    getUrlDe: function() {
      if (currentLang === 'de') {
          return window.location.href;
      }

      if (URLPart === "") {
          return "/lang-de"
      }

      return URLPart;
    },

    getUrlFr: function() {
        if (currentLang === "fr") {
            return window.location.href;
        }

        if (URLPart === "") {
            return "/lang-fr"
        }

        return URLPart;

    }

  },
  mounted: function() {
    let vm = this;

    StickyBits("#navigation-main", {
      useStickyClasses: true,
      verticalPosition: "top"
    });

    if (window.appdata.ie) {
      let tb = document.getElementById("navigation-main");
      if (tb.style.position != "sticky") {
        const efficientStickyHandler = helper.debounce(function() {
          let sa = document.getElementById("sticky-area");
          if (sa.clientWidth) {
            tb.style.width = sa.clientWidth + "px";
          }
        }, 99);
        window.addEventListener(
          "resize",
          efficientStickyHandler,
          window.supportsPassive ? { passive: true } : false
        );
        efficientStickyHandler();
      }
    }
  },
  methods: {
    translate: function(text) {
        return transBio(text)
    },
    toggleMenu: function() {
      this.menuExpanded = !this.menuExpanded;
      if (!this.menuExpanded) {
        let vm = this;
        setTimeout(function() {
          vm.burgerClass = "";
          vm.navbarClass = "";
        }, 250);
        window.EventBus.$emit("toggleMainMenu");
      } else {
        this.burgerClass = "burger--close";
        this.navbarClass = "navbar-condensed";
        setTimeout(function() {
          window.EventBus.$emit("toggleMainMenu");
        }, 250);
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/components/navbar";
@import "../../styles/components/hamburger";
</style>
