import { lang_de } from "./de";

let lang_fr = [];
lang_fr["Suchen"] = "Suchen";
lang_fr["Welcher Husten hat Sie erwischt?"] = "Quelle toux avez-vous ?";
lang_fr["Mit wenigen Klicks zum passenden Produkt!"] =
  "Le bon produit en quelques clics!";
lang_fr["Los geht's"] = "c'est parti";
lang_fr["Haufig gesucht"] = "Häufig gesucht:";
lang_fr["Wie koennen wir Ihnen helfen"] = "Wie können wir Ihnen helfen?";

export { lang_fr };
