<template>
  <transition name="fade">
    <div class="families-teaser-row family-cluster">
      <b-row class="no-gutters family-group">
        <b-col
          v-for="(family, index) in items"
          :key="`img-${index}`"
          :order-sm="`${family.order}`"
          sm
          :class="`col-${family.code}`"
          class="col-family mb-4"
        >
          <div class="family-body position-relative">
            <div class="family-text">
              <div class="family-claim menu-text">
                <a :href="family.url">
                  <font-awesome-icon class="text-angle" icon="angle-right" />
                  <span v-html="family.text" />
                </a>
              </div>
            </div>

            <div class="family-image position-relative">
              <a :href="family.url">
                <img
                  :src="packshots[`${family.code}`]"
                  :alt="family.titleRaw"
                  class="packshot"
                />
              </a>

              <div
                v-if="family.code != 'origin'"
                class="medizinprodukt position-absolute"
              >

              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </transition>
</template>

<script>
import * as helper from "../../lib/helper";

const hasClass = function(ele, cls) {
  return ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
};

let families = JSON.parse(JSON.stringify(window.products || [])); // duplicate family, don't modify original
helper.makeNavSanofi(families);

families.forEach(function(family) {
  family.order = family.code == "origin" ? 1 : family.code == "phyto" ? 2 : 3;
});

export default {
  name: "TeaserFamily",
  data: function() {
    return {
      families: families
    };
  },
  computed: {
    items: function() {
      let items = [];
      let vm = this;
      this.families.forEach(function(family) {
        family.text =
          "<strong>" +
          family.title +
          "</strong><br><small>" +
          family.viewBag.claimShort +
          "</small>";
        items.push(family);
      });

      return items;
    },
    packshots: function() {
      let packshots = [];
      let lang_namespace = "";

      if (window.appdata.lang == "fr") {
          lang_namespace = "fr_";
      }

      Object.keys(window.familyPackshots).forEach(function(cluster) {

        if (
          window.assetrevisions.hasOwnProperty(
            "img/packshots/family/" + lang_namespace  + window.familyPackshots[cluster] + ".png"
          )
        ) {
          packshots[cluster] =
            "/themes/mucosolvan/dist/" +
            window.assetrevisions[
              "img/packshots/family/" + lang_namespace + window.familyPackshots[cluster] + ".png"
            ];
        } else {
          packshots[cluster] =
            "/themes/mucosolvan/dist/assets/img/packshots/family/" + lang_namespace +
            window.familyPackshots[cluster] +
            ".png";
        }
      });

     // console.log({final: packshots});

      return packshots;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/components/menu-family";
</style>
