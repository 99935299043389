<template>
  <img :src="iconPath" height="25" class="svg-icon" />
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    icon: {
      type: String,
      required: true
    },
    cluster: {
      type: String,
      required: true
    }
  },
  computed: {
    iconPath: function() {
      if (
        window.assetrevisions.hasOwnProperty(
          "icon/" + this.icon + "-" + this.cluster + ".svg"
        )
      ) {
        return (
          "/themes/mucosolvan/dist/" +
          window.assetrevisions[
            "icon/" + this.icon + "-" + this.cluster + ".svg"
          ]
        );
      } else {
        return (
          "/themes/mucosolvan/dist/assets/icon/" +
          this.icon +
          "-" +
          this.cluster +
          ".svg"
        );
      }
    }
  }
};
</script>
