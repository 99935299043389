<template>
  <div :class="display" class="load-bar" @showLoadbar="showLoadBar">
    <div class="bar" />
  </div>
</template>

<script>
export default {
  name: "LoadBar",
  data: function() {
    return {
      display: "d-none"
    };
  },
  mounted: function() {
    var vm = this;
    window.EventBus.$on("showLoadBar", function() {
      vm.showLoadBar();
    });
    window.EventBus.$on("hideLoadBar", function() {
      setTimeout(vm.hideLoadBar, 333);
    });
    /* this.$nextTick(function() {
      this.showLoadBar();
      console.log("LB created");
    }); */
  },
  methods: {
    showLoadBar: function() {
      // console.log("show LB");
      this.display = "";
    },
    hideLoadBar: function() {
      // console.log("hide LB");
      this.display = "d-none";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/custom";

.load-bar {
  position: fixed;
  z-index: $zindex-fixed;
  top: 0;
  width: 100%;
  height: 3px;
  border-radius: 3;
  background-color: transparent;

  .bar {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 100%;
    left: 0;
    opacity: 1;
    text-align: center;

    &:nth-child(1) {
      background-color: $primary;
      animation: loading 0.67s linear infinite;
    }
  }
}

@keyframes loading {
  0% {
    opacity: 0;
    width: 0;
    left: 0;
  }
  50% {
    opacity: 1;
    width: 100%;
    left: 0;
  }
  100% {
    opacity: 0;
    width: 100%;
    left: 100%;
  }
}
</style>
