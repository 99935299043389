<template>
  <div :class="{ expanded: isExpanded }">
    <div class="search-form">
      <b-form
        :class="{ collapsed: !isExpanded }"
        class="navbar-search-form"
        action="/suche"
        @submit="onSubmit"
      >
        <b-input-group>
          <b-form-input
            v-model.lazy.trim="form.suchtext"
            type="text"
            name="q"
            :placeholder="translate('Navbar.Search.Placeholder')"
          />
          <b-input-group-append>
            <b-button variant="primary" type="submit">
              <font-awesome-icon
                :icon="['fas', searchIcon]"
                :spin="iconSpin"
                size="lg"
                aria-hidden="true"
              />
              <span class="sr-only">Suchen</span>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </div>
  </div>
</template>

<script>
let findParentForm = function(elem) {
  var parent = elem.parentNode;
  if (parent && parent.tagName != "FORM") {
    parent = findParentForm(parent);
  }
  return parent;
};

import * as helper from "../lib/helper";

export default {
  name: "NavbarSearch",
  data: function() {
    return {
      form: {
        suchtext: ""
      },
      searchIcon: "search",
      iconSpin: false,
      isExpanded: false
    };
  },
  mounted: function() {
    this.form.suchtext = this.getParameterByName("q");
  },
  methods: {
    translate: function(text) {
        return helper.transBio(text);
    },
    doSubmit: function(event) {
      let form = findParentForm(event.target);
      if (this.form.suchtext.length > 0) {
        form.submit();
      }
    },
    onSubmit: function(event) {
      if (event) {
        if (this.form.suchtext.length == 0) {
          event.preventDefault();
        }
        if (this.isExpanded) {
          if (this.form.suchtext == "") {
            event.preventDefault();
            this.toggle();
          } else {
            window.EventBus.$emit("showLoadBar");
            this.searchIcon = "spinner";
            this.iconSpin = true;
          }
        } else {
          event.preventDefault();
          this.toggle();
        }
      }
    },
    toggle: function() {
      this.isExpanded = !this.isExpanded;
    },
    getParameterByName: function(name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(window.location.href);
      if (results == null) return "";
      else return decodeURIComponent(results[1].replace(/\+/g, " "));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/custom";
@import "../../styles/mixins";

.input-group {
  .form-control {
    max-width: 220px;
    @include media-breakpoint-up(md) {
      transition: max-width 0.33s ease-in-out;
    }
  }
  @include media-breakpoint-up(md) {
    .btn.btn-primary {
      transition: all 0.33s ease-in-out;
    }
  }
}

.collapsed {
  .input-group {
    .form-control {
      padding: 0;
      border: 0;
      max-width: 0;
    }
    .btn.btn-primary {
      background-color: transparent;
      border-color: transparent;
      color: $primary;
      box-shadow: none;
    }
  }
}

/* responsive search bar */
.navbar-main .navbar-container > .expanded {
  margin: 0 auto;
}

@include media-breakpoint-down(sm) {
  .navbar-main .navbar-container > .expanded {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    min-height: 20px;
    background-color: $light;

    .search-form {
      position: absolute;
      min-width: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .form-control {
        max-width: 100%;
      }
    }
  }
}
</style>
