import "promise-polyfill/src/polyfill";

import themeCss from "../styles/app.scss";
window.themeCss = themeCss;

import Vue from "vue";
Vue.config.devtools = true;

import axios from "axios";
window.axios = axios;
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
window.axios.defaults.headers.common = {
  "X-CSRF-TOKEN": window.laravel.csrfToken,
  "X-Requested-With": "XMLHttpRequest"
};
window.axios.defaults.timeout = 5000;

import { EventBus } from "./event-bus.js";
window.EventBus = EventBus;

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo, {
  container: "body",
  duration: 333,
  easing: "ease-out",
  offset: -160,
  cancelable: false,
  onStart: function(target) {
    // console.log(target);
    void null;
  },
  onDone: function(target) {
    // console.log(target);
    void null;
  },
  onCancel: function() {
    void null;
  },
  x: false,
  y: true
});

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  preLoad: 2,
  observer: true,
  observerOptions: {
    rootMargin: "0px",
    threshold: 0
  },
  silent: true,
  error: "/themes/mucosolvan/assets/img/error.png",
  loading: "/themes/mucosolvan/assets/img/pixel.png"
});

import InViewportDirective from "vue-in-viewport-directive";
Vue.directive("in-viewport", InViewportDirective);

import VueBar from "vuebar";
Vue.use(VueBar);

/*
payed adobe font, we don't have that.
import daxKit from "./dax-kit.js";
window.daxKit = daxKit;
import FontFaceObserver from "fontfaceobserver";
const dax = new FontFaceObserver("ff-dax-pro");
dax.load().then(function() {
  // console.log("Dax Family has loaded");

});*/

document.documentElement.classList.add("fontsloaded");

import LoadBar from "./components/LoadBar.vue";
import SvgIcon from "./components/SvgIcon.vue";
import BreadCrumbs from "./components/BreadCrumbs.vue";
import NavBar from "./components/NavBar.vue";
import TagSearch from "./components/TagSearch.vue";
import RelatedCards from "./components/RelatedCards.vue";
import PageFooter from "./components/PageFooter.vue";
import MainMenu from "./components/MainMenu.vue";
import TeaserFamily from "./components/products/TeaserFamily.vue";
import HustenTest from "./components/HustenTestWrapper.vue";
import PflichtPop from "./components/PflichtPop.vue";
// import MenuFamily from "./components/products/MenuFamily.vue";
import CookieLaw from "vue-cookie-law";

const docBody = document.getElementsByTagName("body")[0];

import(/* webpackChunkName: "bsvue-fa5" */ "./components-bsvue-fa5").then(
  function() {
    const baseComponents = {
      "load-bar": LoadBar,
      "svg-icon": SvgIcon,
      "bread-crumbs": BreadCrumbs,
      "navigation-bar": NavBar,
      "tag-search": TagSearch,
      "related-cards": RelatedCards,
      "page-footer": PageFooter,
      "main-menu": MainMenu,
      "teaser-family": TeaserFamily,
      "husten-test": HustenTest,
      "pflicht-pop": PflichtPop,
      // "menu-family": MenuFamily,
      "cookie-law": CookieLaw
    };
    const additionalComponents = window.additionalComponents || {};
    const components = Object.assign(baseComponents, additionalComponents);

    let app = new Vue({
      el: "#oc-app",
      components: components,
      data: function() {
        return {
          pageOverflow: ""
        };
      },
      mounted: function() {
        let vm = this;
        docBody.classList.remove("loading");
        setTimeout(this.markReady, 1000);
        window.EventBus.$on("toggleMainMenu", function() {
          vm.pageOverflow = vm.pageOverflow == "pinned" ? "" : "pinned";
          if (vm.pageOverflow == "pinned") {
            docBody.classList.add("pinned");
          } else {
            docBody.classList.remove("pinned");
          }
        });
        window.EventBus.$on("hideMainMenu", function() {
          vm.pageOverflow = "";
        });
        setTimeout(this.scrollToHash, 666);

        /* setTimeout(this.cachePage, 2000); */
      },
      methods: {
        markReady: function() {
          /* helper 4 scraper */
          let fjs = document.getElementById("endOfPage");
          let readyDiv = document.createElement("div");
          readyDiv.id = "page-rendered";
          fjs.parentNode.insertBefore(readyDiv, fjs);
        },
        scrollToHash: function() {
          let scrollTarget = location.hash;
          if (scrollTarget !== undefined && scrollTarget != "") {
            scrollTarget = scrollTarget.slice(1);
            // console.log(scrollTarget);
            this.$scrollTo(document.getElementById(scrollTarget), 500);
          }
        }
        /* cachePage: function() {
        let vm = this;
        if (
          window.laravel.host == "mucosolvan.local" &&
          window.laravel.pageid
        ) {
          console.log("caching...");
          this.axios
            .post("/api/btdsgn/cache-page", {
              name: window.laravel.pageid,
              content: vm.$el.outerHTML
            })
            .then(function(response) {
              console.log(response.data);
            });
        }
      }, */
      }
    });
    window.app = app;
  }
);

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    // the "I am a keyboard user" key
    docBody.classList.add("user-is-tabbing");
    window.removeEventListener("keydown", handleFirstTab);
  }
}
window.addEventListener(
  "keydown",
  handleFirstTab,
  window.supportsPassive ? { passive: true } : false
);
