var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-result-box",class:_vm.cardclasses,on:{"click":_vm.onClick}},[(_vm.product)?_c('div',[_c('img',{staticClass:"product-itself product-img",attrs:{"src":_vm.packshot,"alt":_vm.productName}}),_vm._v(" "),(!_vm.test || _vm.product)?_c('img',{staticClass:"darreichungsform",attrs:{"src":_vm.darreichungsform,"alt":_vm.darreichung}}):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"h2"},[_c('span',{class:_vm.cname,domProps:{"innerHTML":_vm._s(_vm.result.title)}}),_vm._v(" "),(!_vm.product || _vm.test)?_c('span',[_c('br'),_c('span',{staticClass:"subtitle",class:("sb-product-" + _vm.product)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.result.subtitle)}})])]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"card-body"},[(
        _vm.result.slug == 'produkte-bisolvon-phyto-complete' ||
          _vm.result.slug == 'produkte-bisolvonhustenstiller' ||
          _vm.result.slug == 'produkte-bisolvonhs' ||
          _vm.result.slug == 'produkte-bisolvonlp' ||
          _vm.result.slug == 'produkte-phyto' ||
          _vm.result.slug == 'produkte-mucodual'
      )?_c('div',{staticClass:"medizinprodukt"}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"d-none"},[_vm._v(" "+_vm._s(_vm.result.relevance)+" ")]),_vm._v(" "),_c('a',{staticClass:"btn btn-more",attrs:{"href":_vm.result.url}},[_vm._v(" "+_vm._s(_vm.translate('ResultCard.Mehr'))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }