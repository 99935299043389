let lang_de = [];
lang_de["Suchen"] = "Suchen";
lang_de["Welcher Husten hat Sie erwischt?"] =
  "Welcher Husten hat Sie erwischt?";
lang_de["Mit wenigen Klicks zum passenden Produkt!"] =
  "Mit wenigen Klicks zum passenden Produkt!";
lang_de["Los geht's"] = "Los geht's";
lang_de["Haufig gesucht"] = "Häufig gesucht:";
lang_de["Wie koennen wir Ihnen helfen"] = "Wie können wir Ihnen helfen?";

export { lang_de };
