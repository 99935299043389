<template>
  <div id="tag-search" class="container mt-3 mb-5">
    <p class="text-center mb-2 h2">{{ translate("Wie koennen wir Ihnen helfen") }}</p>
    <b-form ref="form" action="/suche">
      <b-input-group :size="inputGroupSize" class="mx-auto my-2">
        <b-form-input
          id="tagsearch"
          v-model.lazy.trim="searchterm"
          :placeholder="translate('search_placeholder')"
          class="form-control"
          name="q"
          @change="doSearch"
        />
        <b-input-group-append>
          <b-button variant="primary" type="submit" @click.prevent="doSearch">
            <font-awesome-icon
              :icon="['fas', searchIcon]"
              :spin="iconSpin"
              size="lg"
              aria-hidden="true"
            />
            <span class="sr-only">{{translate('TagSearch.Suchen')}}</span>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
    <p class="common-tags text-center mt-1">
      <small>{{ translate('Haufig gesucht') }}</small> <br class="d-block d-sm-none" />
      <b-button
        v-for="tag in commonTags"
        :key="`tag-${tag}`"
        variant="outline-primary"
        class="ml-2"
        @click="addTag"
      >
        {{ tag }}
      </b-button>
    </p>

    <div v-if="untouched" class="card husten-test d-flex flex-row mt-5">
      <div class="card-body d-flex justify-content-center align-items-center">
        <div class="card-card text-center py-5 px-3 px-md-5 husten-test-teaser">
          <div class="h1 mb-2">{{ translate('Welcher Husten hat Sie erwischt?') }}</div>
          <p class="h2 font-weight-normal mb-5">
            {{ translate('Mit wenigen Klicks zum passenden Produkt!') }}
          </p>
          <p>
            <a :href="getTestUrl()" class="btn btn-primary">
              {{ translate("Los geht's") }}
            </a>
          </p>
        </div>
      </div>
    </div>

    <div v-if="!results.length" class="search-spinner text-center my-5">
      <font-awesome-icon :icon="['fas', 'spinner-third']" size="4x" spin />
    </div>
    <b-row class="results-wrap justify-content-center">
      <b-col
        v-for="result in results"
        :key="result.slug"
        v-in-viewport.once="{ top: 100, bottom: 100 }"
        :md="result.span"
        :class="result.appear"
      >
        <resultCard :result="result" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import * as helper from "../lib/helper";
import resultCard from "./ResultCard.vue";

let from = "from-left";

let commonTags = [];
window.tags.forEach(function(tag) {
  if (tag.cluster.indexOf(window.tagsCluster) > -1) {
    commonTags.push(tag.tag);
  }
});


export default {
  name: "TagSearch",
  components: {
    resultCard
  },
  data: function() {
    return {
      inputGroupSize: "",
      untouched: true,
      searching: false,
      searchterm: "",
      searchIcon: "search",
      iconSpin: false,
      commonTags: commonTags,
      lang: window.appdata.lang,
      results: [],
      efficientResizeHandler: false
    };
  },
  mounted: function() {
    this.getDefaults();
    let vm = this;
    this.efficientResizeHandler = helper.debounce(function() {
      const wWidth = window.innerWidth;
      if (wWidth < 992) {
        if (wWidth < 576) {
          vm.inputGroupSize = "sm";
        } else {
          vm.inputGroupSize = "";
        }
      } else {
        vm.inputGroupSize = "lg";
      }
    }, 99);
    window.addEventListener(
      "resize",
      this.efficientResizeHandler,
      window.supportsPassive ? { passive: true } : false
    );
    this.efficientResizeHandler();
  },
  destroy: function() {
    window.removeEventListener("resize", this.efficientResizeHandler);
  },
  methods: {
    getTestUrl() {
        let url = "/husten-test";

        if (window.appdata.lang == "fr") {
            return "/fr/test-de-la-toux"
        }

        return url;

    },
    translate: function(text) {
        return helper.transBio(text);
    },
    addTag: function(event) {
      this.searchterm = event.target.innerText;
      this.getResults();
    },
    doSearch: function() {
      // console.log("searching...");
      this.getResults();
    },
    getResults: function() {
      this.untouched = false;
      if (!this.searching) {
        this.searching = true;
        let vm = this;
        window.EventBus.$emit("showLoadBar");
        this.searchIcon = "spinner";
        this.iconSpin = true;
        vm.results = [];
        this.axios
          .post("/api/btdsgn/search", { searchterm: this.searchterm })
          .then(function(response) {
            if (response.data.length > 0) {
              helper.makeNavSanofi(response.data);
              response.data.forEach(function(item) {
                from = from == "from-left" ? "from-right" : "from-left";
                item.appear = "appear " + from;
                item.span = 4;
                if (item.crumb[0] == "produkte") {
                  item.title = item.title.replace("&reg;", "&reg;<br>");
                }
                if (item.crumb[0] == "wirkprinzip") {
                  item.title = item.meta_title;
                }
              });
              vm.results = response.data;
              vm.searchIcon = "search";
              vm.iconSpin = false;
              window.EventBus.$emit("hideLoadBar");
              if (vm.searchterm != "") {
                vm.$scrollTo(document.getElementById("tag-search"));
              }
            } else {
              /* trigger /search if nothing found */
              vm.$refs.form.submit();
            }
            vm.searching = false;
          })
          .catch(function(error) {
            // handle error
            console.error(error);
          });
      }
    },
    getDefaults: function() {
      let default_cards = window.default_cards;
      let searchlist = default_cards.pluck("url");

      let vm = this;
      vm.results = [];
      this.axios
        .post("/api/btdsgn/searchDefault", { searchlist: searchlist })
        .then(function(response) {
          window['boo'] = response.data;
          helper.makeNavSanofi(response.data);
          let counter = 0;

            if (typeof response.data.forEach !== 'function') {
                vm.results = response.data;
                return ;
            }

          response.data.forEach(function(item) {
            item.appear = "";
            if (counter == 1) {
              item.appear += " span-double";
            }
            item.span = counter == 1 ? 8 : 4;
            if (item.crumb[0] == "produkte") {
              item.title = item.title.replace("&reg;", "&reg;<br>");
            }
            if (item.crumb[0] == "wirkprinzip") {
              item.title = item.meta_title;
            }
            counter++;
          });

          vm.results = response.data;
        })
        .catch(function(error) {
          // handle error
          console.error(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/components/tagsearch";
</style>
