<template>
  <div class="test-teaser-row mx-sm-auto pt-2">
    <div :class="childClass" class="teaser-test row justify-content-center">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3024 1062" v-if="ellipse" v-bind:svg-inline="''" v-bind:width="'3024'" v-bind:height="'1062'" v-bind:class="'test-ellipse-bg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><ellipse cx="1512" cy="531" rx="1512" ry="531" fill="#fff" data-name="ellipse"/></svg>
      <img
        v-else
        src="../../layout/ellipse.png"
        width="3024"
        height="1062"
        class="test-ellipse-bg"
      />

      <div class="test-body pb-5"><HustenTest /></div>
    </div>
  </div>
</template>

<script>
import * as helper from "../lib/helper";
import HustenTest from "./test/HustenTest.vue";

let ellipse = true;
if (helper.hasClass(document.getElementById("body-element"), "ie")) {
  ellipse = false;
}

export default {
  name: "HustenTestWrapper",
  components: {
    HustenTest
  },
  data: function() {
    return {
      ellipse: ellipse,
      childClass: ""
    };
  },
  mounted: function() {
    var vm = this;
    window.EventBus.$on("setChildTest", function() {
      vm.childClass = "is-child";
    });
    window.EventBus.$on("unsetChildTest", function() {
      vm.childClass = "";
    });
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/components/husten-test-wrapper";
</style>
