<template>
  <ul :class="`level-${level}`" class="ml-4 nav flex-column">
    <li v-for="item in items" :key="item.id" class="nav-item">
      <a
        :href="item.url"
        :class="item.isActive || item.isChildActive ? 'active' : ''"
        class="nav-link"
        @click="dispatchClick"
      >
        <span v-html="item.title" />
      </a>
      <main-sub-menu
        v-if="item.items && item.items.length"
        :items="item.items"
        :level="level + 1"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: "MainSubMenu",
  components: {
    MainSubMenu: require("./MainSubMenu.vue").default
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    level: {
      type: Number,
      required: true
    }
  },
  methods: {
    dispatchClick: function(event) {
      // suppress parent collapse closing:
      event.stopPropagation();
    }
  }
};
</script>
