<template>
  <div>
    <div class="card position-relative husten-test px-4 mx-4">
      <div class="d-flex justify-content-center">
        <ul class="progressbar my-5">
          <li
            v-for="(question, index) in quiz.questions"
            :key="`progress-${index}`"
            :class="{
              active: index == questionIndex,
              finished: index < questionIndex
            }"
            class="bar"
          />
          <li
            :class="{ active: questionIndex == quiz.questions.length }"
            class="bar"
          />
          <li
            :class="{ active: questionIndex == quiz.questions.length }"
            class="result text-xs"
          >
            {{ translate('HustenTest.Result.Text') }}
          </li>
        </ul>
      </div>

      <div v-for="(question, index) in quiz.questions" :key="question.id">
        <transition name="fade" class="question-fader">
          <div v-show="index === questionIndex" class="test-question">
            <div class="h2 text-center mb-4">{{ question.text }}</div>
            <div class="d-flex justify-content-center">
              <b-button
                v-for="answer in question.answers"
                :id="`htbtn-${index}-${answer.value}`"
                :key="answer.id"
                variant="outline-primary"
                class="mx-1 mx-md-2"
                @click="next(answer.value)"
              >
                {{ answer.text }}
              </b-button>
            </div>
          </div>
        </transition>
      </div>

      <transition name="fade">
        <div v-if="questionIndex === quiz.questions.length">
          <div class="h3 d-none">Ergebnis:</div>
          <div v-if="result().type == 'baby'"><result-baby /></div>
          <div v-if="result().type == 'dual'"><result-dual /></div>
          <div v-if="result().type == 'phyto'"><result-phyto /></div>
          <div v-if="result().type == 'ambroxol'"><result-ambroxol /></div>
          <div v-if="result().type == 'ambroxolkinder'">
            <result-ambroxol-kinder />
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div
          v-show="questionIndex > 0"
          class="clearfix reset-option text-center py-2"
        >
          <b-button size="sm" variant="link" @click="reset">
            <font-awesome-icon icon="angle-right" size="sm" />
              <span v-html="translate('HustenTest.Restart')"></span>
          </b-button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import * as helper from "../../lib/helper";

import ResultBaby from "./ResultBaby.vue";
import ResultDual from "./ResultDual.vue";
import ResultPhyto from "./ResultPhyto.vue";
import ResultAmbroxol from "./ResultAmbroxol.vue";
import ResultAmbroxolKinder from "./ResultAmbroxolKinder.vue";
import {transBio} from "../../lib/helper";

const quiz_fr = {
    questions: [
        {
            text: "Quel est l'âge du patient?",
            id: "age",
            answers: [
                {
                    text: "1–2 ans",
                    id: "respbaby",
                    value: "baby"
                },
                {
                    text: "2–12 ans",
                    id: "respchild",
                    value: "child"
                },
                {
                    text: "> 12 ans",
                    id: "respadult",
                    value: "adult"
                }
            ]
        },
        {
            text: "Comment est la toux ? ",
            id: "muede",
            answers: [
                {
                    text: "Plutôt grasse",
                    id: "respmucous",
                    value: "mucous"
                },
                {
                    text: "Plutôt sèche avec une irritation de la gorge",
                    id: "respphyto",
                    value: "phyto"
                },
                {
                    text: "Je ne sais pas / Les deux",
                    id: "respboth",
                    value: "both"
                }
            ]
        }
    ]
};

const quiz = {
  questions: [
    {
      text: "Wie alt ist der Patient?",
      id: "age",
      answers: [
        {
          text: "1–2 Jahre",
          id: "respbaby",
          value: "baby"
        },
        {
          text: "2–12 Jahre",
          id: "respchild",
          value: "child"
        },
        {
          text: "älter als 12 Jahre",
          id: "respadult",
          value: "adult"
        }
      ]
    },
    {
      text: "Wie fühlt sich der Husten an?",
      id: "muede",
      answers: [
        {
          text: "Eher verschleimt",
          id: "respmucous",
          value: "mucous"
        },
        {
          text: "Eher trocken mit Halsbeschwerden",
          id: "respphyto",
          value: "phyto"
        },
        {
          text: "Ich Weiss es nicht / Beides",
          id: "respboth",
          value: "both"
        }
      ]
    }
  ]
};


export default {
  name: "HustenTest",
  components: {
    ResultBaby,
    ResultDual,
    ResultPhyto,
    ResultAmbroxol,
    ResultAmbroxolKinder
  },
  data: function() {

    if (window.appdata.lang == "fr") {
      return {
        quiz: quiz_fr,
        questionIndex: 0,
        userResponses: []
      };
    }

    return {
        quiz: quiz,
        questionIndex: 0,
        userResponses: []
    };

  },
  methods: {
    translate: function(text) {
        return transBio(text);
    },
    // Go to next question
    next: function(answer) {
      let vm = this;
      let target = false;
      this.userResponses[this.questionIndex] = answer;
      if (answer == "baby" || answer == "dry") {
        target = this.quiz.questions.length;
      }
      if (
        this.userResponses.indexOf("baby") > -1 ||
        this.userResponses.indexOf("child") > -1
      ) {
        window.EventBus.$emit("setChildTest");
      }
      setTimeout(function() {
        if (target !== false) {
          vm.questionIndex = target;
        } else {
          vm.questionIndex++;
        }
      }, 250);
    },
    result: function() {
      if (this.questionIndex === this.quiz.questions.length) {
        let result = {};
        if (this.userResponses.indexOf("baby") > -1) {
          result.text = transBio('HustenTest.Result.Baby');
          result.type = "baby";
        } else {
          if (this.userResponses.indexOf("mucous") > -1) {
            if (this.userResponses.indexOf("child") > -1) {
              result.text = transBio('HustenTest.Result.Child.A');
              result.type = "ambroxolkinder";
            } else {
              result.text =transBio('HustenTest.Result.Child.B');
              result.type = "ambroxol";
            }
          } else if (this.userResponses.indexOf("phyto") > -1) {
            result.text = transBio('HustenTest.Result.Dual');
            result.type = "dual";
          } else if (this.userResponses.indexOf("both") > -1) {
            result.text =transBio('HustenTest.Result.Child.C');
            result.type = "phyto";
          }
        }
        // result.text = helper.makeTextSanofi(result.text);

        //  console.log(result);

        return result;
      }
    },
    reset: function() {
      window.EventBus.$emit("unsetChildTest");
      let vm = this;
      setTimeout(function() {
        vm.questionIndex = 0;
      }, 250);
      this.userResponses = [];
      this.scrollTop();
    },
    scrollTop: function() {
      this.$scrollTo(document.getElementById("oc-app"), 333);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/components/husten-test";
</style>
