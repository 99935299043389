<template>
  <div class="position-fixed obligationframe">
    <div class="pp-wrapper position-relative bg-light">
      <div
        id="closePop"
        class="position-absolute close-pflicht-pop"
        @click="closePop"
      >
        <font-awesome-icon
          :icon="['fas', 'window-close']"
          size="lg"
          class="text-danger"
          aria-hidden="true"
        />
        <span class="sr-only">schliessen</span>
      </div>
      <div class="pp-container shadow">
        <div
          class="pp-body"
        >
          <div>
            <div
              class="py-2 pl-3 pr-4 hyphenate robots-noindex robots-nocontent legal"
            >
              <span v-html="content"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PflichtPop",
  props: {
      content: {
          type: String,
          required: false
      }
  },
  methods: {
    closePop: function() {
      this.$parent.$emit("close:popup");
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/components/pflichtpop";
</style>
