import lang_de from "../translations/de";
import lang_fr from "../translations/fr";

if (!Array.prototype.hasOwnProperty("pluck")) {
  Object.defineProperty(Array.prototype, "pluck", {
    value: function(key) {
      return this.map(function(object) {
        return object[key];
      });
    }
  });
}

export function trans_fr() {
  let lang = [];
  // TagSearch.vue
  lang["Welcher Husten hat Sie erwischt?"] = "Quelle toux avez-vous?";
  lang["Mit wenigen Klicks zum passenden Produkt!"] =
    "Le bon produit en quelques clics!";
  lang["Los geht's"] = "C'est parti";
  lang["Haufig gesucht"] = "Fréquemment recherché:";
  lang["Wie koennen wir Ihnen helfen"] = "Comment pouvons-nous vous aider?";
  lang["search_placeholder"] = "Entrer le terme de recherche";
  lang["TagSearch.Suchen"] = "Recherche";

  // Pflichttext.vue
  lang["PflichtText.Footer"] =
    "Bisolvon®, Bisolvon® Ambroxol et Bisolvon® Dextromthorphan : Ce sont des médicaments autorisés. Lisez la notice d'emballage.\n" +
    "<br>Bisolvon® Phyto Complete, Bisolvon® Phyto Complete  SANS SUCRE, Bisolvon® Dual 2in1 sirop und Bisolvon® Dual 2in1 pastilles à sucer: Ce sont des dispositifs médicaux. Lisez les notices d'emballage.\n" +
    "<br>\n" +
    "<br><small>Consultez un spécialiste et lisez la notice.</small>\n" +
    "<br><small>SACH.BISO2.18.11.0974(1)</small>";

  // NavbarSearch.vue
  lang["Navbar.Search.Placeholder"] = "Recherche";

  //NavBar.vue
  lang["Navbar.Menu.Label"] = "Afficher plus ou masquer";
  lang["Navbar.Menu"] = "Menu";

  // MainMenu.vue
  lang["MainMenu.Produkte"] = "Produits";

  //PageFooter.vue
  lang["PageFooter.LogoDescription"] = "Sanofi";
  lang["PageFooter.Produkte"] = "Produits";
  lang["PageFooter.Info.Header"] = "Renseignements importants";
  lang["PageFooter.Info.Details"] =
    "Habituellement, la toux est une maladie que vous pouvez traiter vous-même. Les informations\n" +
    " suivantes vous donnent la meilleure information possible, mais ne peuvent remplacer une\n" +
    " visite chez le médecin. Si vos symptômes sont particulièrement graves ou s'ils ne\n" +
    " s'améliorent pas après quelques jours, consultez un médecin. Selon la gravité et la durée de\n" +
    " vos symptômes, vous devriez consulter un médecin.";
  lang["PageFooter.Company.Header"] = "Société";
  lang["PageFooter.Company.Details"] =
    "<strong>sanofi-aventis (suisse) sa</strong><br />\n" +
    "Route de Montfleury, 3 <br />\n" +
    "Postfach 777<br />\n" +
    "1214 VERNIER Suisse";
  lang["PageFooter.Copyright.Text"] =
    '<span class="neverwrap">Copyright &copy; 2019 sanofi-aventis (suisse) sa.</span>\n' +
    '<span class="neverwrap">Tous droits réservés.</span>\n' +
    '<span class="neverwrap">Date d\'actualisation du site: 18.02.2019 | SACH.BISO2.18.11.0974(1)</span>';

  // FooterNavService.vue
  lang["FooterNavService.Products"] = "Autres produits CHC";

  //RelatedCards.vue
  lang["RelatedCards.Also"] = "Ces sujets peuvent également vous intéresser:";

  //ResultCard.vue
  lang["ResultCard.Mehr"] = "Plus";

  //TeaserFamily.vue
  lang["TeaserFamily.Mehr"] = "plus";

  //Products
  //ProductCards.vue
  lang["ProductCards.VerschleimterHusten"] = "- En cas de toux grasse";
  lang["ProductCards.WeitereProdukte"] = "Autres produits";

  //Teaser Single
  lang["TeaserSingle.ZumProdukt"] = "Voir le Produit";

  //HustenTest.vue
  lang["HustenTest.Restart"] = "Recommencer le test de la toux";
  lang["HustenTest.Result.Text"] = "Résultat";
  lang["HustenTest.Result.Baby"] =
    "Choix possible: Bisolvon® Sirop contre la toux pour les enfants et consultation médicale!";
  lang["HustenTest.Result.Dual"] = "Choix possible: Bisolvon Dextromethorphan";
  lang["HustenTest.Result.Child.A"] =
    "Choix possible: Bisolvon® Sirop contre la toux pour les enfants!";
  lang["HustenTest.Result.Child.B"] =
    "Choix possible: Bisolvon® Ambroxol Retard ou Bisolvon® Sirop contre la toux";
  lang["HustenTest.Result.Child.C"] =
    "Choix possible: Bisolvon® Phyto Complete (dispositifs médicaux)";

  // used on all ResultXX.vue below
  lang["HustenText.General.AllProdukte"] = "Tous les produits";

  //ResultAmbroxol.vue
  lang["ResultAmbroxol.ResultText"] = "Nos produits par la toux avec mucus:";

  //ResultBaby.vue
  lang["ResultBaby.ResultText"] =
    "Nous avons un <strong> très jeune patient </strong>! " +
    "Dans ce cas, il est conseillé de " +
    "<strong> consulter le spécialiste de votre confiance </strong> et de " +
    "clarifier le médicament idéal.";

  lang["ResultBaby.OneYear"] = "à partir d’un an";

  //ResultAmbroxolKinder.vue
  lang["ResultAmbroxolKinder.ResultText"] =
    "Nos produits par la toux avec mucus:";

  //ResultDual.vue
  lang["ResultDual.ResultText"] = "Notre produit sur la toux:";

  //ResulyPhyto.cue
  lang["ResultPhyto.ResultText"] =
    "Notre produit pour la toux sèche et la toux grasse :";

  return lang;
}

export function trans_de() {
  let lang = [];
  // TagSearch.vue
  lang["Welcher Husten hat Sie erwischt?"] = "Welcher Husten hat Sie erwischt?";
  lang["Mit wenigen Klicks zum passenden Produkt!"] =
    "Mit wenigen Klicks zum passenden Produkt!";
  lang["Los geht's"] = "Los geht's";
  lang["Haufig gesucht"] = "Häufig gesucht:";
  lang["Wie koennen wir Ihnen helfen"] = "Wie können wir Ihnen helfen?";
  lang["search_placeholder"] = "Suchbegriff eingeben";
  lang["TagSearch.Suchen"] = "Suchen";

  // Pflichttext.vue
  lang["PflichtText.Footer"] =
    "Bisolvon®, Bisolvon® Ambroxol und Bisolvon® Dextromethorphan: Dies sind zugelassene Arzneimittel. Lesen Sie die Packungsbeilage.\n" +
    "          <br>Bisolvon® Phyto Complete, Bisolvon® Phyto Complete  ZUCKERFREI, Bisolvon® Dual 2in1 Sirup und Bisolvon® Dual 2in1 Lutschtabletten: Dies sind Medizinprodukte. Lesen Sie die Packungsbeilage.\n" +
    "          <br>\n" +
    "          <br><small>Diese Website ist nur für Einwohner der Schweiz bestimmt. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.</small>\n" +
    "          <br><small>SACH.BISO2.18.11.0974(1)</small>";

  // NavbarSearch.vue
  lang["Navbar.Search.Placeholder"] = "Suchbegriff eingeben";

  //NavBar.vue
  lang["Navbar.Menu.Label"] = "Menü anzeigen oder verstecken";
  lang["Navbar.Menu"] = "Menu";

  // MainMenu.vue
  lang["MainMenu.Produkte"] = "Produkte";

  //PageFooter.vue
  lang["PageFooter.LogoDescription"] = "eine Website von Sanofi";
  lang["PageFooter.Produkte"] = "Produkte";
  lang["PageFooter.Info.Header"] = "Wichtige Informationen";
  lang["PageFooter.Info.Details"] =
    "In der Regel ist Husten eine Beschwerde, die Sie selbst behandeln\n" +
    " können. Die nachfolgenden Informationen geben Ihnen die\n" +
    " bestmöglichen Informationen, können jedoch einen Arztbesuch nicht\n" +
    " ersetzen. Sind Ihre Beschwerden besonders stark oder sollten sich\n" +
    " die Beschwerden nicht nach einigen Tagen verbessern, sollte ein\n" +
    " Arzt aufgesucht werden. Je nach Stärke und Länge Ihrer Beschwerden\n" +
    " sollte ein Arzt aufgesucht werden.";
  lang["PageFooter.Company.Header"] = "Unternehmen";
  lang["PageFooter.Company.Details"] =
    "<strong>sanofi-aventis (schweiz) ag</strong><br />\n" +
    "Route de Montfleury, 3 <br />\n" +
    "Postfach 777<br />\n" +
    "1214 VERNIER Schweiz";
  lang["PageFooter.Copyright.Text"] =
    '<span class="neverwrap">Copyright &copy; 2019 sanofi-aventis (schweiz) ag.</span>\n' +
    '<span class="neverwrap">Alle Rechte vorbehalten.</span>\n' +
    '<span class="neverwrap">Seite aktualisiert: 18.02.2019 | SACH.BISO2.18.11.0974(1)</span>';

  // FooterNavService.vue
  lang["FooterNavService.Products"] = "Andere CHC-Produkte";

  //RelatedCards.vue
  lang["RelatedCards.Also"] = "Diese Themen könnten Sie auch interessieren:";

  //ResultCard.vue
  lang["ResultCard.Mehr"] = "Mehr";

  //TeaserFamily.vue
  lang["TeaserFamily.Mehr"] = "mehr";

  //Products
  //ProduCards.vue
  lang["ProductCards.VerschleimterHusten"] = "- bei verschleimtem Husten";
  lang["ProductCards.WeitereProdukte"] = "Weitere Produkte";

  //Teaser Single
  lang["TeaserSingle.ZumProdukt"] = "zum Produkt";

  //HustenTest.vue
  lang["HustenTest.Restart"] = "Hustentest neu starten";
  lang["HustenTest.Result.Text"] = "Ergebnis";
  lang["HustenTest.Result.Baby"] = "Baby: Bisolvon Kids & Arztbesuch";
  lang["HustenTest.Result.Dual"] =
    "trocken: Bisolvon Dextromethorphan Hustenstiller Bei Reizhusten";
  lang["HustenTest.Result.Child.A"] =
    "Auswurf: Bisolvon Praktische Husten-Lösungen bei verschleimtem Husten Kindersaft";
  lang["HustenTest.Result.Child.B"] =
    "Auswurf: Bisolvon Praktische Husten-Lösungen bei verschleimtem Husten Hustensaft Retardkapseln";
  lang["HustenTest.Result.Child.C"] =
    "Phyto: Bisolvon Phyto Complete 100% natürlich bei trockenem oder verschleimtem Husten (Medizinprodukt)";

  // used on all ResultXX.vue below
  lang["HustenText.General.AllProdukte"] = "alle Produkte";

  //ResultAmbroxol.vue
  lang["ResultAmbroxol.ResultText"] =
    "Unsere Produkte bei verschleimtem Husten:";

  //ResultBaby.vue
  lang["ResultBaby.ResultText"] =
    "      Da haben wir ja einen <strong>besonders jungen Patienten</strong>! In\n" +
    "      diesem Fall ist es ratsam,\n" +
    "      <strong>den Facharzt Ihres Vertrauens zu konsultieren</strong> und die\n" +
    "      ideale Medikation abzuklären.";

  lang["ResultBaby.OneYear"] = "ab einem Jahr";

  //ResultAmbroxolKinder.vue
  lang["ResultAmbroxolKinder.ResultText"] =
    "Unsere Produkte bei verschleimtem Husten:";

  //ResultDual.vue
  lang["ResultDual.ResultText"] = "Unser Produkt bei Reizhusten:";

  //ResulyPhyto.cue
  lang["ResultPhyto.ResultText"] =
    "Unser Produkt bei trockenem Husten bis zu Husten mit Schleim:";

  return lang;
}

export function transBio(text) {
  if (!window.hasOwnProperty("appdata")) {
    console.log("appdata not ava");
    return text;
  }

  var lang = window.appdata.lang;

  if (lang === undefined) {
    return text;
  }

  let lang_arr = trans_de();

  if (lang === "fr") {
    lang_arr = trans_fr();
  }

  if (lang_arr[text] !== undefined) {
    return lang_arr[text];
  }

  return text + " !XX! ";
}

export function makeTextSanofi(product) {
  product = product
    .replace("2in1 Sirup", '<span class="nowrap">2in1 Sirup</span>')
    .replace("Phyto Complete", '<span class="nowrap">Phyto Complete</span>')
    .trim();

  if (product.indexOf("&reg;") == -1) {
    if (product.indexOf("_") == -1) {
      return product; // product.trim() + '<sup class="reg">&reg;</sup>';
    }

    return product.replace("_", '<sup class="reg">&reg;</sup>');
  }

  return product.replace("&reg;", '<sup class="reg">&reg;</sup>');
}

export function makeNavSanofi(items) {
  if (typeof items.forEach !== "function") {
    return;
  }

  items.forEach(function(item) {
    item.titleRaw = item.title;
    item.title = makeTextSanofi(item.title);
    if (item.viewBag && item.viewBag.claim) {
      item.viewBag.claim = makeTextSanofi(item.viewBag.claim);
    }
    if (item.subtitle) {
      item.subtitle = makeTextSanofi(item.subtitle);
    }
    /* item.title =
      vorfahre == "" ? makeTextSanofi(item.title) : vorfahre + " " + item.title; */
    if (item.items && item.items.length) {
      makeNavSanofi(item.items);
    }
  });
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    if (!wait) wait = 100;
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
/*
var myEfficientFn = debounce(function() {
  // All the taxing stuff you do
}, 250);
window.addEventListener('resize', myEfficientFn, window.supportsPassive ? { passive: true } : false);
*/

export function getUrlParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function createFunctionWithTimeout(callback, opt_timeout) {
  var called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, opt_timeout || 1000);
  return fn;
}

// extendable and cancelable Timeouts
export function setAdvancedTimer(f, delay) {
  var obj = {
    firetime: delay + +new Date(), // the extra + turns the date into an int
    called: false,
    canceled: false,
    callback: f
  };
  // this function will set obj.called, and then call the function whenever
  // the timeout eventually fires.
  var callfunc = function() {
    obj.called = true;
    f();
  };
  // calling .extend(1000) will add 1000ms to the time and reset the timeout.
  // also, calling .extend(-1000) will remove 1000ms, setting timer to 0ms if needed
  obj.extend = function(ms) {
    // break early if it already fired
    if (obj.called || obj.canceled) return false;
    // clear old timer, calculate new timer
    clearTimeout(obj.timeout);
    obj.firetime += ms;
    var newDelay = obj.firetime - new Date(); // figure out new ms
    if (newDelay < 0) newDelay = 0;
    obj.timeout = setTimeout(callfunc, newDelay);
    return obj;
  };
  // Cancel the timer...
  obj.cancel = function() {
    obj.canceled = true;
    clearTimeout(obj.timeout);
  };
  // call the initial timer...
  obj.timeout = setTimeout(callfunc, delay);
  // return our object with the helper functions....
  return obj;
}

export function hasClass(ele, cls) {
  return ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
var supportsPassive = false;
try {
  var opts = Object.defineProperty({}, "passive", {
    get: function() {
      supportsPassive = true;
    }
  });
  window.addEventListener("testPassive", null, opts);
  window.removeEventListener("testPassive", null, opts);
} catch (e) {}
window.supportsPassive = supportsPassive;
