<template>
  <transition name="fade">
    <div>
      <div id="pflichttext-footer" class="disclaimer robots-noindex robots-nocontent legal py-4">
        <div class="h4" v-html="translate('PflichtText.Footer') ">
        </div>
      </div>
      <div v-if="hasPop">
        <pflicht-pop :class="showpop" :content="poptext">
        </pflicht-pop>
      </div>
    </div>
  </transition>
</template>

<script>
import inViewport from "vue-in-viewport-mixin";
import PflichtPop from "./PflichtPop.vue";

import * as helper from "../lib/helper";
const tag = helper.getUrlParameterByName("tag");
let PopTextEx = "";

if (window.hasOwnProperty('productData')) {
  if (window.productData.hasOwnProperty('popText')) {
      if (window.productData.popText !== "") {
          PopTextEx = window.productData.popText;
      }
  }
}

let isMarketing = false;
if (window.hasOwnProperty('laravel')) {
    if (window.laravel.hasOwnProperty('asMarketing')) {
        if (window.laravel.asMarketing !== "") {
            isMarketing = true;
        }
    }
}


const mucosolvan = helper.makeTextSanofi("Bisolvon&reg;");

export default {
  name: "PflichtText",
  components: {
    PflichtPop
  },
  mixins: [inViewport],
  data: function() {

      let hasPop = false;

      if (PopTextEx !== "") {
          hasPop = true;
      }

    return {
      mucosolvan: mucosolvan,
      showbutton: true,
      poptext: PopTextEx,
       // '<div class="h4">Produktinformationen: <span class="neverwrap">' +
        // mucosolvan +
        // "</span></div>",
      pflichttexte: [],
      pflichtprimary: [],
      pflichtsecondary: [],
      cookie: "",
      showpop: "",
      hasPop: hasPop
    };
  },
  /* watch: {
    "inViewport.now": function(visible) {
      console.log(
        "Pflichtext im Footer ist " +
          (visible
            ? "im Dartsellungsbereich"
            : "ausserhalb des Dartsellungsbereichs")
      );
    }
  }, */
  mounted: function() {
    this.cookie = this.getCookie("snfDisclaimer");
    var vm = this;
    let lawyer = document.getElementById("cookie-lawyer");

    if (PopTextEx === "" || isMarketing !== true) {
        return;
    }

    var vm = this;
    this.axios
      .get("/api/btdsgn/aggregate-static/_pflichttexte.json")
      .then(function(response) {
        vm.pflichttexte = response.data;
        for (let i = 0; i < 3; ++i) {
          vm.pflichtprimary.push({
            key: "mandatory-" + i,
            content: vm.pflichttexte[i]
          });
        }
        for (let i = 3; i < vm.pflichttexte.length; ++i) {
          vm.pflichtsecondary.push({
            key: "mandatory-" + i,
            content: vm.pflichttexte[i]
          });
        }
        vm.cookie = vm.getCookie("snfDisclaimer");
        let lawyer = document.getElementById("cookie-lawyer");
        if ((vm.cookie != "seen" || tag !== null) && window.displayProductPop === true) {
          vm.pflichttexte.forEach(function(product) {
            vm.poptext += product.markup;
          });
          vm.showpop = "pop";
          if (!lawyer) {
            vm.showpop = "pop bottom";
          }
          //vm.setCookie("snfDisclaimer", "seen");
        }
          vm.showpop = "pop";
        console.log(vm.showpop);
        vm.$on("close:popup", function() {
          vm.closePop();
        });
        vm.$on("close:popup", function() {
          vm.closePop();
        });
      });
  },
  methods: {
    translate: function(text) {
        return helper.transBio(text);
    },
    setCookie: function(name, value) {
      var today = new Date();
      today.setTime(today.getTime());
      var expires = 1000 * 60 * 45; // 45 min.
      var expires_date = new Date(today.getTime() + expires);
      document.cookie =
        name +
        "=" +
        escape(value) +
        ";expires=" +
        expires_date.toGMTString() + //expires.toGMTString()
        ";path=/";
    },
    getCookie: function(name) {
      var start = document.cookie.indexOf(name + "=");
      var len = start + name.length + 1;
      if (!start && name != document.cookie.substring(0, name.length)) {
        return null;
      }
      if (start == -1) return null;
      var end = document.cookie.indexOf(";", len);
      if (end == -1) end = document.cookie.length;
      return unescape(document.cookie.substring(len, end));
    },
    hideButton: function() {
      this.showbutton = false;
    },
    closePop: function() {
      this.showpop = "";
    }
  }
};
</script>
