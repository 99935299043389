<template>
  <div>
    <div class="mt-2 mb-3">
      <strong>{{translate('ResultAmbroxolKinder.ResultText')}}</strong>
    </div>
    <hr />

    <b-row class="results justify-content-center">
      <div class="col-sm-6 mb-2"><resultCard :result="kindersaft" /></div>
    </b-row>

    <p>
      <a href="/produkte" class="nav-link">
        <font-awesome-icon class="text-muted" icon="angle-right" />
        <strong class="alle-produkte">{{translate('HustenText.General.AllProdukte')}}</strong>
      </a>
    </p>
  </div>
</template>

<script>
import * as helper from "../../lib/helper";
import resultCard from "../ResultCard.vue";
import {transBio} from "../../lib/helper";

let mainmenu = JSON.parse(JSON.stringify(window.mainmenu || [])); // duplicate mainmenu, don't modify original
let mucosolvan = {};
let rest = {};
mainmenu.forEach(function(item) {
  let crumb = [];
  let from = "from-left";
  let rc = 0;

  let langCheck    = "Produkte";

  if (window.appdata.lang == "fr") {
      langCheck    = "Produits";
  }

  if (!item.viewBag.isHidden || item.viewBag.isHidden == 0) {
    if (item.title == langCheck) {
      item.items.forEach(function(item) {
        if (!item.viewBag.isHidden || item.viewBag.isHidden == 0) {
          if (item.title == "Bisolvon&reg;" || item.title == "Bisolvon_") {
            helper.makeNavSanofi(item.items);
            let mc = 0;
            item.items.forEach(function(product) {
              from = from == "from-left" ? "from-right" : "from-left";
              crumb = product.url.split("/");
              crumb.shift();
              product.crumb = crumb;
              product.slug = crumb.join("-");
              product.from = from;
              product.subtitle = product.viewBag.subTitle
                ? helper.makeTextSanofi(product.viewBag.subTitle)
                : "";
              product.title = product.title.replace("&reg;", "&reg;<br>");
              mucosolvan[product.slug] = product;
              mc++;
            });
          } else {
            item.title = helper.makeTextSanofi(item.title);
            from = from == "from-left" ? "from-right" : "from-left";
            crumb = item.url.split("/");
            crumb.shift();
            if (crumb[1] == "bisolvon-phyto-complete") {
              crumb[1] = "phyto";
            }
            if (crumb[2] == "bisolvon-phyto-complete") {
              crumb[2] = "phyto";
            }
            item.crumb = crumb;
            item.slug = crumb.join("-");
            item.from = from;
            item.subtitle = item.viewBag.subTitle
              ? helper.makeTextSanofi(item.viewBag.subTitle)
              : "";
            item.clusterTitle = item.title;
            item.title = item.title.replace("&reg;", "&reg;<br>");
            rest[item.slug] = item;
            rc++;
          }
        }
      });
    }
  }
});

//console.log({rest:rest, mu: mucosolvan});

export default {
  name: "ResultAmbroxol",
  components: {
    resultCard
  },
  data: function() {

    if (window.appdata.lang == "fr") {
        return {
            kindersaft: mucosolvan["fr-produit-bisolvon-enfants"]
        };
    }

    return {
      kindersaft: mucosolvan["produkte-bisolvon-kids"]
    };
  },
  methods: {
      translate: function(text) {
          return transBio(text);
      },
  }
};
</script>

<style lang="scss">
@import "../../../styles/custom";
@import "../../../styles/mixins";
.results {
  .card {
    .h3 {
      @include responsive-font-size($font-size-base);
    }
  }
}
</style>
