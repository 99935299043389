<template>
  <div :class="cardclasses" class="card card-result-box" @click="onClick">
    <div v-if="product">
      <img
        :src="packshot"
        :alt="productName"
        class="product-itself product-img"
      />
      <img
        v-if="!test || product"
        :src="darreichungsform"
        :alt="darreichung"
        class="darreichungsform"
      />
    </div>

    <div class="card-header">
      <div class="h2">
        <span v-html="result.title" :class="cname" />
        <span v-if="!product || test">
          <br /><span class="subtitle" :class="`sb-product-${product}`"
            ><span v-html="result.subtitle"
          /></span>
        </span>
      </div>
    </div>

    <div class="card-body">
      <div
        v-if="
          result.slug == 'produkte-bisolvon-phyto-complete' ||
            result.slug == 'produkte-bisolvonhustenstiller' ||
            result.slug == 'produkte-bisolvonhs' ||
            result.slug == 'produkte-bisolvonlp' ||
            result.slug == 'produkte-phyto' ||
            result.slug == 'produkte-mucodual'
        "
        class="medizinprodukt"
      >

      </div>
      <span class="d-none"> {{ result.relevance }} </span>
      <a :href="result.url" class="btn btn-more"> {{translate('ResultCard.Mehr')}} </a>
    </div>
  </div>
</template>

<script>
import {transBio} from "../lib/helper";

const hasClass = function(ele, cls) {
  return ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
};

export default {
  name: "ResultCard",
  props: {
    result: {
      type: Object,
      required: true
    },
    cname: {
        type: String,
        default: 'generic',
        required: false
    },
  },
  computed: {
    cardclasses: function() {
      let classes = [];
      //console.log({res:this.result});
      this.result.crumb.forEach(function(crumb) {
        classes.push("card-" + crumb);
      });
      classes = classes.join(" ");
      return classes;
    },
    productName: function() {
      let productName = false;
      this.result.crumb.forEach(function(crumb) {
        productName = crumb;
      });
      return productName.charAt(0).toUpperCase() + productName.slice(1);
    },
    product: function() {
      let product = false;
      this.result.crumb.forEach(function(crumb) {
          //console.log({crumb:crumb});
        if (crumb == "produkte" || crumb == "produit") {
          product = true;
        }
      });

      return product;
    },
    test: function() {
      return this.result.test ? true : false;
    },
    packshot: function() {
      let name = "";
      let path = "";
      this.result.crumb.forEach(function(crumb) {
        name = crumb;
      });

        let lang_namespace = "";

        if (window.appdata.lang == "fr") {
            lang_namespace = "fr_";
        }

      if (
        window.assetrevisions.hasOwnProperty("img/packshots/" + lang_namespace + name + ".png")
      ) {
        return (
          "/themes/mucosolvan/dist/" +
          window.assetrevisions["img/packshots/" + lang_namespace + name + ".png"]
        );
      } else {
        return "/themes/mucosolvan/dist/assets/img/packshots/" + lang_namespace + name + ".png";
      }
    },
    darreichungsform: function() {
      let name = "dummy";
      if (this.result.id) {
        name = this.result.id.split("-").pop();
      } else {
        if (this.result.slug) {
          name = this.result.slug.split("-").pop();
        }
      }

        let lang_namespace = "";

        if (window.appdata.lang == "fr") {
            lang_namespace = "fr/";
        }

      if (
        window.assetrevisions.hasOwnProperty(
          "img/cards/darreichungsform/" + lang_namespace + name + ".png"
        )
      ) {
        return (
          "/themes/mucosolvan/dist/" +
          window.assetrevisions["img/cards/darreichungsform/" + lang_namespace + name + ".png"]
        );
      } else {
        return (
          "/themes/mucosolvan/dist/assets/img/cards/darreichungsform/" + lang_namespace +
          name +
          ".png"
        );
      }
    },
    darreichung: function() {
      let name = "dummy";
      if (this.result.id) {
        name = this.result.id.split("-").pop();
      } else {
        if (this.result.slug) {
          name = this.result.slug.split("-").pop();
        }
      }

      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  },
  methods: {
    translate: function(text) {
        return transBio(text);
    },
    onClick: function(event) {
      top.location.href = this.result.url;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/components/result-card.scss";
</style>
