<template>
  <transition name="fade">
    <b-nav vertical>
      <b-nav-item
        v-for="item in navigation"
        :key="item.id"
        :active="item.isActive"
        :href="item.url"
        :target="item.viewBag.isExternal != 0 ? '_blank' : '_top'"
      >
        <font-awesome-icon icon="angle-right" size="lg" />
        <span
          :id="item.code ? item.code : false"
          :data-code="item.code ? item.code : false"
          v-html="item.title"
        />
      </b-nav-item>
      <div class="dropdownchc">
        <span class="iconarrow"><font-awesome-icon icon="angle-right" size="lg" /></span> <strong>{{ translate('FooterNavService.Products') }}</strong>
        <div class="chc-products">
          <a href="https://www.bioflorin.ch" target="_blank">Bioflorin<sup>&reg;</sup></a>
          <a href="https://www.buscopan.ch" target="_blank">Buscopan<sup>&reg;</sup></a>
          <a href="https://www.aspegic.ch" target="_blank">Aspegic<sup>&reg;</sup></a>
          <a href="https://www.allergo.ch" target="_blank">Allergo<sup>&reg;</sup></a>
          <a href="https://www.antistax.ch" target="_blank">Antistax<sup>&reg;</sup></a>
          <a href="https://www.pharmaton.ch" target="_blank">Pharmaton<sup>&reg;</sup></a>
          <a href="https://www.dulcolax.ch" target="_blank">Dulcolax<sup>&reg;</sup></a>
        </div>
      </div>
    </b-nav>
  </transition>
</template>

<script>
import * as helper from "../lib/helper";

let serviceNav = [];
let servicemenu = JSON.parse(JSON.stringify(window.servicemenu || [])); // duplicate, don't modify original

servicemenu.forEach(function(item, index) {
  if (item.viewBag.isHidden == 0) {
    item.id = index;
    serviceNav.push(item);
  }
});

export default {
  name: "FooterNavigationService",
  data: function() {
    return {
      navigation: serviceNav,
      showImportentInfo: false
    };
  },
  methods: {
    translate: function(text) {
      return helper.transBio(text);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/custom";
@import "../../styles/mixins";

.nav-item {
  .nav-link {
    padding-left: 0;
    padding-bottom: 0;
  }

  .active {
    color: $muted;
  }

  .svg-inline--fa {
    color: $muted;
    position: relative;
    top: 0;
  }
}

.dropdownchc {
  position: relative;
  display: inline-block;
  margin-top: 7px;
}

.iconarrow {
  color: $muted;
}

.dropdownchc a {
  text-decoration: none;
}

.chc-products {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 125px;
  z-index: 1;
}

.chc-products a {
  color: #45236c;;
  padding: 6px 12px;
  display: block;
}

.chc-products a:hover {
  background-color: #f1f1f1
}

.dropdownchc:hover .chc-products {
  display: block;
}
</style>
